import React from "react";
import PropTypes from "prop-types";
import { PopoverBody } from "react-bootstrap";
import NavChild from "./NavChild";

const LinkPopover = (props) => {
  const { showChildren, item, session, activeItemId, handleChildClick } = props;

  const popoverbody = showChildren
    ? item.children &&
      item.children.map((child, index) => (
        <NavChild
          child={child}
          className="py-2 mx-n3 ps-3 pe-5"
          session={session}
          activeItemId={activeItemId}
          handleChildClick={handleChildClick}
          key={`hover_child_${index}`}
          activeChild={false}
        />
      ))
    : null;

  return <PopoverBody className="py-1">{popoverbody}</PopoverBody>;
};

LinkPopover.propTypes = {
  showChildren: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  session: PropTypes.object,
  activeItemId: PropTypes.string,
  handleChildClick: PropTypes.func,
};

LinkPopover.defaultProps = {
  session: null,
  activeItemId: null,
  handleChildClick: () => {}, //NOSONAR
};

export default LinkPopover;
