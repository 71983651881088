import React from "react";
import { Route } from "react-router-dom";
import CustomLayout from "./CustomLayout/CustomLayout";
import PropTypes from "prop-types";
import { getSession, logoutWithNext, isUserLoggedIn } from "utils/auth";
import { evaluatePermission } from "utils/permissions";
import NoAuth from "./NoAuth";
import { userSessionCookie } from "utils/config";
import { LoadingProvider } from "hooks/useLoading";

const AuthRoute = ({
  component: C,
  props: cProps,
  plainLayout,
  initialLoadState,
  ...rest
}) => {
  const authorize = (authorizedComponent, permission) => {
    const sessionData = getSession(userSessionCookie);
    if (isUserLoggedIn(sessionData)) {
      const isPermitted = permission
        ? evaluatePermission(permission, sessionData)
        : true;
      return isPermitted ? authorizedComponent : <NoAuth />;
    } else {
      logoutWithNext();
    }
  };

  const routeComponent = (
    <Route
      {...rest}
      render={(props) => <C {...props} {...cProps} inProgress />}
    />
  );

  const activeItemId = cProps.activeItemId ? cProps.activeItemId : "";

  const layoutView = (
    <CustomLayout activeItemId={activeItemId}>{routeComponent}</CustomLayout>
  );

  const authComponent = plainLayout ? routeComponent : layoutView;

  return (
    <LoadingProvider initialLoadState={initialLoadState}>
      {authorize(authComponent, cProps.permission)}
    </LoadingProvider>
  );
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  props: PropTypes.object,
  plainLayout: PropTypes.bool,
  initialLoadState: PropTypes.bool,
};

AuthRoute.defaultProps = {
  props: {},
  plainLayout: false,
  initialLoadState: false,
};

export default AuthRoute;
