import React from "react";
import PropTypes from "prop-types";

import "./PageLoader.scss";

const PageLoader = (props) => {
  // If there was an error while loading the component
  if (props.error) {
    // eslint-disable-next-line no-console
    console.log("Error in loading page, info: ", props);
    // eslint-disable-next-line no-console
    console.log("Further info: ", props.error);

    window.location.reload();
  }

  // Show Loading screen only if the component is taking more than 200ms to load
  if (props.inProgress || props.pastDelay) {
    return (
      <div className="modal-wrapper">
        <div className="modal-backdrop ">
          <div className="modal fade show" tabIndex="-1" role="dialog">
            <div className="loader d-flex align-items-center justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

PageLoader.propTypes = {
  inProgress: PropTypes.bool,
  pastDelay: PropTypes.bool,
  error: PropTypes.bool,
};

PageLoader.defaultProps = {
  error: false,
  inProgress: true,
  pastDelay: false,
};

export default PageLoader;
