import React from "react";
import PropTypes from "prop-types";
import { isUserAllowed } from "utils/permissions";

const NavChild = (props) => {
  const {
    child,
    activeChild,
    handleChildClick,
    className,
    activeItemId,
    session,
  } = props;

  const isChildActive = activeItemId === child.id;
  const activeClass =
    activeChild && isChildActive
      ? "text-white bg-primary fw-bold"
      : "hoverable bg-white";

  return isUserAllowed(child, session) ? (
    <div
      className={`${activeClass} ${className}`}
      role="button"
      onClick={() => handleChildClick(child)}
    >
      {child.title}
    </div>
  ) : null;
};

NavChild.propTypes = {
  child: PropTypes.object.isRequired,
  activeChild: PropTypes.bool,
  handleChildClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  activeItemId: PropTypes.string,
  session: PropTypes.object,
};

NavChild.defaultProps = {
  className: "",
  activeItemId: null,
  session: null,
  activeChild: true,
};

export default NavChild;
