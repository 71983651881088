import React from "react";
import PropTypes from "prop-types";
import { userPermissions } from "utils/permissions";
import { appRoutes } from "utils/config";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import NavItem from "components/NavItem/NavItem";

const SettingNavItem = (props) => {
  const { session, handleSettingClick, showMenu } = props;
  const partner = session && session.userprofile && session.userprofile.partner;

  const supportsSlots =
    partner && partner.collection && partner.collection_slots;
  const supportsDelivery = partner && partner.delivery;

  let navItem = {
    displayName: "Settings",
    route: appRoutes.businessSettings,
    permissions: [
      userPermissions.VIEW_PAYMENTSETTINGS,
      userPermissions.VIEW_BANNER,
      userPermissions.VIEW_SECTION,
      userPermissions.VIEW_POPULAR_BAKERIES,
      userPermissions.CHANGE_PARTNER,
    ],
    icon: faCog,
  };
  if (supportsSlots) {
    navItem.permissions = [
      ...navItem.permissions,
      userPermissions.VIEW_COLLECTIONSLOT,
    ];
  }
  if (supportsDelivery) {
    navItem.permissions = [
      ...navItem.permissions,
      userPermissions.VIEW_DELIVERY_POLICY,
    ];
  }
  return (
    <NavItem
      item={navItem}
      session={session}
      showMenu={showMenu}
      className="mt-auto setting-nav"
      handleNavClick={handleSettingClick}
      showChildren={false}
    />
  );
};

SettingNavItem.propTypes = {
  session: PropTypes.object,
  handleSettingClick: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
};

SettingNavItem.defaultProps = {
  session: null,
};

export default SettingNavItem;
