import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const CustomToggle = forwardRef(({ children, onClick, toggleClass }, ref) => (
  <div
    ref={ref}
    role="button"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className={toggleClass}
  >
    {children}
  </div>
));

CustomToggle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  toggleClass: PropTypes.string,
};

CustomToggle.defaultProps = {
  toggleClass: "",
};

export default CustomToggle;
