/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";

import { isUserLoggedIn } from "../utils/auth";

export default ({ component: C, props: cProps, ...rest }) => {
  const { redirectionRoute } = cProps;

  return isUserLoggedIn() ? (
    <Redirect to={redirectionRoute} />
  ) : (
    <Route {...rest} render={(props) => <C {...props} {...cProps} />} />
  );
};
