import React from "react";
import PropTypes from "prop-types";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Collapse = (props) => {
  const { showMenu, toggleMenu, className } = props;
  const deskCollapseIcon = showMenu ? faAngleDoubleLeft : faAngleDoubleRight;
  const tabCollapseIcon = !showMenu ? faAngleDoubleLeft : faAngleDoubleRight;

  return (
    <div className={`position-fixed bg-white bottom-0 ${className} hoverable`}>
      <div role="button" onClick={() => toggleMenu()}>
        <div className="text-nowrap d-none d-lg-flex border-top py-3 overflow-hidden align-items-center">
          <div className="d-flex justify-content-center item-icon">
            <FontAwesomeIcon
              icon={deskCollapseIcon}
              className="d-none d-xl-block"
            />
            <FontAwesomeIcon icon={tabCollapseIcon} className="d-xl-none" />
          </div>
          <span>Collapse</span>
        </div>
        <div className="text-nowrap d-flex d-lg-none border-top py-3 overflow-hidden align-items-center">
          <div className="d-flex justify-content-center item-icon">
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <span>Close sidebar</span>
        </div>
      </div>
    </div>
  );
};

Collapse.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Collapse.defaultProps = {
  className: "",
};

export default Collapse;
