import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./SideNav.scss";
import { SIDENAV_ITEMS, userSessionCookie } from "utils/config";
import { getSession, logout } from "utils/auth";
import Collapse from "./Collapse";
import NavItem from "components/NavItem/NavItem";
import { setSessionStorageItem, sessionKeys } from "utils/session";
import SettingNavItem from "./SettingNavItem";

const SideNav = (props) => {
  const { showMenu, activeItemId } = props;
  const history = useHistory();
  const session = getSession(userSessionCookie);
  if (session && (!session.permissions || !session.permissions.length)) {
    // For old users who loggedin with no permissions in userprofile
    logout(history);
  }

  const handleSettingClick = () => {
    setSessionStorageItem(
      sessionKeys.settingRedirect,
      history.location.pathname
    );
  };

  const sidebarId = "sidebar";

  const sidenavItems = SIDENAV_ITEMS.map((item, index) => {
    return (
      <NavItem
        item={item}
        session={session}
        key={`side_nav_item_${index}`}
        showMenu={showMenu}
        activeItemId={activeItemId}
        className="main-nav-item"
        regularChildrenClass="d-block d-lg-none d-xl-block"
        toggledChildrenClass="d-lg-block d-xl-none"
      />
    );
  });

  const sideNavClass = showMenu ? "regular-sidenav" : "toggled-sidenav";
  const sidebarClass = showMenu ? "regular-width" : "toggled-width";

  return (
    <div className={`side-nav ${props.className} ${sideNavClass}`}>
      <nav
        className={`sidebar hidden-y-scroll flex-column ${sidebarClass}`}
        id={sidebarId}
      >
        <div className="sidenav-list-items pt-3 hidden-y-scroll d-flex flex-column w-100 h-100">
          {sidenavItems}
          <SettingNavItem
            session={session}
            handleSettingClick={handleSettingClick}
            showMenu={showMenu}
          />
          <Collapse
            showMenu={showMenu}
            toggleMenu={props.toggleMenu}
            className={`${sideNavClass} ${sidebarClass}`}
          />
        </div>
      </nav>
    </div>
  );
};

SideNav.propTypes = {
  className: PropTypes.string,
  toggleMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  activeItemId: PropTypes.string,
};

SideNav.defaultProps = {
  className: "",
  activeItemId: null,
};

export default SideNav;
