import React, { useState } from "react";
import PropTypes from "prop-types";
import NavbarHamburger from "../NavbarHamburger/NavbarHamburger";
import SideNav from "../SideNav/SideNav";
import "./CustomLayout.scss";
import { Container } from "react-bootstrap";

const CustomLayout = (props) => {
  const [showMenu, setShowMenu] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const containerLeftSpacing = showMenu ? "regular-menu" : "toggled-menu";

  return (
    <div>
      <NavbarHamburger toggleMenu={toggleMenu} />
      <SideNav
        toggleMenu={toggleMenu}
        showMenu={showMenu}
        activeItemId={props.activeItemId}
      />
      <div className={`below-top-nav pt-3 ${containerLeftSpacing}`}>
        <Container>
          <div>{props.children}</div>
        </Container>
      </div>
    </div>
  );
};

CustomLayout.propTypes = {
  children: PropTypes.any.isRequired,
  pageName: PropTypes.string,
  activeItemId: PropTypes.string,
};

CustomLayout.defaultProps = {
  pageName: "",
  activeItemId: null,
};

export default CustomLayout;
