import axios from "axios";

import { apiRoutes } from "./api-routes";
import { getSession, logoutWithNext } from "./auth";

class AxiosWrapper {
  constructor(sessionCookie, rememberMeCookie) {
    this.sessionCookie = sessionCookie;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.interceptors.response.use(
      (response) => response,
      this.handleAPIError
    );
  }

  getAuthHeader() {
    const sessionData = getSession(this.sessionCookie);
    if (Object.keys(sessionData).length) {
      if (sessionData.key) return { Authorization: `Token ${sessionData.key}` };
    }
    return {};
  }

  _ajax({ method, url, data = {}, headers, token = false }) {
    let requestHeaders = { ...headers };
    if (!token) {
      requestHeaders = { ...headers, ...this.getAuthHeader() };
    }
    return axios({ method, url, data, headers: requestHeaders });
  }

  get(url, data, headers, token) {
    return this._ajax({ method: "get", url, data, headers, token });
  }

  post(url, data, headers, token) {
    return this._ajax({ method: "post", url, data, headers, token });
  }

  patch(url, data, headers, token) {
    return this._ajax({ method: "patch", url, data, headers, token });
  }

  put(url, data, headers, token) {
    return this._ajax({ method: "put", url, data, headers, token });
  }

  delete(url, data, headers, token) {
    return this._ajax({ method: "delete", url, data, headers, token });
  }

  handleAPIError(error) {
    if (error.response && error.response.status === 401) {
      // logout user in this case
      if (error.response.config.url !== apiRoutes.login) {
        logoutWithNext();
      } else {
        return Promise.reject(error);
      }
    } else if (
      error.response &&
      error.response.status === 500 &&
      !(error.response.data instanceof Object)
    ) {
      const err = { ...error };
      err.response.data = { error_code: 1500 };
      return Promise.reject(err);
    } else if (error.message === "Network Error" && !navigator.onLine) {
      // Handle internet connectivity error
      const err = { ...error };
      err.response.data = { error_code: 1530 };
      return err;
    } else {
      return Promise.reject(error);
    }
    return false;
  }
}

export default AxiosWrapper;
