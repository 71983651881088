import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMousePointer } from "@fortawesome/free-solid-svg-icons";

const ActionButton = (props) => {
  return (
    <Button
      variant="link ps-0"
      onClick={props.onClick}
      className={props.classNameButton}
      disabled={props.disabled}
    >
      <FontAwesomeIcon
        className={props.className}
        size={props.size}
        icon={props.icon}
      />{" "}
      {props.title}
    </Button>
  );
};

ActionButton.propTypes = {
  className: PropTypes.string,
  classNameButton: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  className: "",
  classNameButton: "",
  size: "1x",
  icon: faMousePointer,
  onClick: () => {}, //NOSONAR
  title: "",
  disabled: false,
};

export default ActionButton;
