import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { apiRoutes } from "../utils/api-routes";
import { logout } from "../utils/auth";
import { handleError, handleSuccess } from "../utils/toast-handler";

const ChangePassword = (props) => {
  // constants
  const oldPasswordFieldId = "oldPassword";
  const newPasswordFieldId = "newPassword";
  const reNewPasswordFieldId = "reNewPassword";

  // History
  const history = useHistory();

  // States
  const [oldPassword, setOldPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);

  // Handle form change
  const handleChange = (event) => {
    if (event.target.id === oldPasswordFieldId) {
      setOldPassword(event.target.value);
    } else if (event.target.id === newPasswordFieldId) {
      setNewPassword(event.target.value);
    } else if (event.target.id === reNewPasswordFieldId) {
      setReNewPassword(event.target.value);
    }
    setErrors([]);
  };

  // Submit function
  const onSubmit = (event) => {
    event.preventDefault();
    if (!oldPassword) {
      setErrors("Old Password is required");
    } else if (!newPassword) {
      setErrors("New Password is required");
    } else if (newPassword !== reNewPassword) {
      setErrors("Passwords does not match");
    } else if (oldPassword === newPassword) {
      setErrors("Old and New Password should not be same");
    } else {
      const data = {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: reNewPassword,
      };
      window.axios
        .post(apiRoutes.changePassword, data)
        .then((response) => {
          setSubmitted(false);
          handleSuccess("Password Changed");
          logout(history);
        })
        .catch((err) => {
          setSubmitted(false);
          if (err.response.status === 400) {
            setErrors([err.response.data.detail]);
          } else {
            handleError(err);
          }
        });
    }
  };

  // Error message component
  const errorMessage =
    errors.length > 0 ? <Alert variant={"danger"}>{errors}</Alert> : null;

  // Change Password UI
  const changePasswordPage = (
    <Modal show={props.show} onHide={() => props.onHide(false)}>
      <Modal.Header closeButton>
        <h5 className="modal-title">Change Password</h5>
      </Modal.Header>
      {errorMessage}
      <form onSubmit={onSubmit}>
        <Modal.Body>
          <div className="bg-light border rounded w-100 p-3 dash-shadow">
            <div className="form-group">
              <label>Old Password</label>
              <input
                type="text"
                className="form-control"
                id={oldPasswordFieldId}
                value={oldPassword}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                className="form-control"
                id={newPasswordFieldId}
                value={newPassword}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Cofirm New Password</label>
              <input
                type="password"
                className="form-control"
                id={reNewPasswordFieldId}
                value={reNewPassword}
                onChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={submitted ? true : false}
            className="w-100 btn btn-primary"
            type="submit"
          >
            {submitted ? "..." : "Change Password"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );

  // Render
  return changePasswordPage;
};

ChangePassword.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ChangePassword;
