import React from "react";
import CustomLayout from "./CustomLayout/CustomLayout";
import ActionButton from "./ActionButton/ActionButton";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { appRoutes } from "../utils/config";
import { logout } from "../utils/auth";

const NoAuth = () => {
  const history = useHistory(appRoutes.landingPage);
  return (
    <CustomLayout>
      <div className="d-flex align-items-center">
        <ActionButton
          size="lg"
          icon={faArrowLeft}
          onClick={() => {
            history.goBack();
          }}
        ></ActionButton>
        <h5 className="mb-0 fw-bold">Home</h5>
      </div>
      <h3>
        It seems you are not authorized to view this page. Please{" "}
        <span
          role="button"
          className="text-primary"
          onClick={() => logout(history)}
        >
          logout
        </span>{" "}
        and try again
      </h3>
    </CustomLayout>
  );
};

export default NoAuth;
