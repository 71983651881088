import React from "react";
import ReactDOM from "react-dom";

import TagManager from "react-gtm-module";
import App from "./App";
import "./styles/bako.scss";
import AxiosWrapper from "./utils/axios-wrapper";
import * as serviceWorker from "./serviceWorker";
import { userSessionCookie } from "./utils/config";

window.axios = new AxiosWrapper(userSessionCookie);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
