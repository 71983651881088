import Swal from "sweetalert2";

const SWAL_OPTIONS = {
  toast: true,
  position: "top",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 10000,
  background: "#000",
  customClass: {
    title: "text-white",
  },
  timerProgressBar: true,
};
const SWAL_ALERT_ERROR_ICON = "error";
const SWAL_ALERT_SUCCESS_ICON = "success";

const Toast = Swal.mixin({ ...SWAL_OPTIONS, showCloseButton: true });

const handleError = (err) => {
  let message = "";
  if (err.response) {
    message = err.response.statusText;
    if (err.response.status === 400) {
      message = err.response.data.detail || "Invalid data";
    } else if (err.response.status === 500) {
      message = "We are facing some problem, please try again after sometime";
    }
  } else if (err.request) {
    if (!navigator.onLine) {
      message = "No network";
    } else {
      message = "Server error";
    }
  } else {
    message = err.message;
  }
  Toast.fire({ icon: SWAL_ALERT_ERROR_ICON, title: message });
};

const handleErrorMsg = (message) => {
  const title = message || "Bad request";
  Toast.fire({ icon: SWAL_ALERT_ERROR_ICON, title: title });
};

const handleSuccess = (title) => {
  Toast.fire({ icon: SWAL_ALERT_SUCCESS_ICON, title });
};

export { handleError, handleSuccess, handleErrorMsg };
