import React from "react";
import { useHistory } from "react-router-dom";

import { logout } from "../utils/auth";

const Logout = () => {
  const history = useHistory();

  return (
    <div className="logout" role="button" onClick={() => logout(history)}>
      Logout
    </div>
  );
};

export default Logout;
