import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./CustomLayout.scss";
import { handleError } from "utils/toast-handler";
import {
  getSession,
  deleteCookie,
  setSession as setCookieeSession,
} from "utils/auth";
import { appRoutes, userSessionCookie } from "utils/config";
import { getPartner, getBakeryName } from "utils/common";
import { apiRoutes } from "utils/api-routes";
import { Dropdown, Button } from "react-bootstrap";
import CustomToggle from "components/ProductFilter/CustomToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const GetAllBakeries = () => {
  // States
  const [ssn, setSession] = useState({});
  const [activeParnterName, setActivePartnerName] = useState("");
  const [isMultiPartnerUser, setIsMultiPartnerUser] = useState(false);
  const [allPartners, setAllPartners] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  // Function - fetch bakeries
  const fetchBakeries = () => {
    const route = apiRoutes.partners;
    window.axios
      .get(route)
      .then((response) => {
        setAllPartners(response.data);
        if (response.data.length > 1) {
          setIsMultiPartnerUser(true);
        }
      })
      .catch((err) => handleError(err));
  };

  // UseEffect
  useEffect(() => {
    fetchBakeries();
  }, []);

  // useEffect - get session information
  useEffect(() => {
    // If the cookie is not present then redirect to login
    const session = getSession(userSessionCookie);
    if (!session) {
      deleteCookie(userSessionCookie);
      history.push(appRoutes.login);
      return;
    }
    setSession(session);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get bakery domain
    const session = getSession(userSessionCookie);
    if (session && session.userprofile) {
      const partner = getPartner(session);
      const bakeryName = getBakeryName(partner);
      setActivePartnerName(bakeryName);
    }
  }, []);

  // History
  const history = useHistory();

  function handlePartnerChanged(event) {
    const { target } = event;
    const activePartner = target.getAttribute("name");
    if (ssn && ssn.userprofile) {
      let tempPartner = {};
      let activePartnerList = allPartners.filter(
        (partner) => partner.name === activePartner
      );
      const activePartnerDictionary = activePartnerList[0];
      tempPartner["name"] = activePartnerDictionary["name"];
      tempPartner["store_code"] = activePartnerDictionary["code"];
      tempPartner["postcode"] = activePartnerDictionary["postcode"];
      tempPartner["delivery"] = activePartnerDictionary["supports_delivery"];
      tempPartner["collection"] =
        activePartnerDictionary["supports_collection"];
      tempPartner["collection_slots"] =
        activePartnerDictionary["supports_collection_slots"];
      tempPartner["domain_name"] = activePartnerDictionary["domain_name"];
      ssn.userprofile.partner = tempPartner;
      setCookieeSession(ssn, userSessionCookie);
      setActivePartnerName(activePartner);
      window.location.reload();
    }
  }

  let partnerDropdown;
  partnerDropdown = allPartners.map((partner, index) => (
    <Dropdown.Item key={`partner_item_${index}`}>
      <div
        className="logout"
        role="button"
        name={partner.name}
        onClick={handlePartnerChanged}
      >
        {partner.name}
      </div>
    </Dropdown.Item>
  ));

  if (isMultiPartnerUser)
    return (
      <Dropdown
        className="dropdown-bakery-name mb-3 mb-lg-0"
        onToggle={() => setShowDropdown(!showDropdown)}
      >
        <Dropdown.Toggle as={CustomToggle}>
          <Button
            variant="outline-light"
            className="m-auto hamburger-navbar-dropdown p-3"
          >
            <span className="me-4">{activeParnterName}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Menu>{partnerDropdown}</Dropdown.Menu>
      </Dropdown>
    );
  else return null;
};

export default GetAllBakeries;
