import React, { useContext, createContext, useState } from "react";
import PropTypes from "prop-types";
import PageLoader from "components/PageLoader/PageLoader";

const LoadingContext = createContext({
  isLoading: true,
  setIsLoading: () => {}, //NOSONAR
});

const LoadingProvider = (props) => {
  const { children, initialLoadState } = props;
  const [isLoading, setIsLoading] = useState(initialLoadState);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <PageLoader inProgress={isLoading} />
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node,
  initialLoadState: PropTypes.bool,
};

LoadingProvider.defaultProps = {
  children: null,
  initialLoadState: false,
};

const useLoading = () => useContext(LoadingContext);

export default useLoading;
export { LoadingProvider };
