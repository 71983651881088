import { SIDENAV_ITEMS } from "./config";

const invalidPhoneMessage =
  "This phone number format is not accepted. Please enter a 10 digit valid phone number (without a leading zero. E.g. 7912345678)";

// Function to get two digits for amount
function getFractionAmount(amount) {
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

// Function - get partner
function getPartner(ssn) {
  if (ssn && ssn.userprofile) {
    return ssn.userprofile.partner;
  }
}

// Function - get bakery name
function getBakeryName(partner) {
  const partnerName = partner && partner.name;
  return partnerName || "";
}

// Function - Active Bakery Domain
function getBakeryDomain(partner) {
  const bakeryDomain = partner && partner.domain_name;
  return bakeryDomain;
}

// Function - Get Page Name from window.location pathName
function getPageName(pathName) {
  const pageList = pathName.split("/");
  const pageNameId = pageList[1];
  for (let index = 0; index < SIDENAV_ITEMS.length; index++) {
    const pageDictionary = SIDENAV_ITEMS[index];
    if (pageDictionary.id === pageNameId) return pageDictionary.displayName;
  }
  return "";
}

// Function to get either number or - if it is less than or equal to zero
function getAmount(amount) {
  if (amount === 0) {
    return "-";
  } else if (amount < 0) {
    amount *= -1;
    return `- £ ${getFractionAmount(amount / 100)}`;
  }
  return `£ ${getFractionAmount(amount / 100)}`;
}

const scrollToField = (id, block = "center") => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block });
  }
};

const getQueryParamValue = (history, query) => {
  return new URLSearchParams(history.location.search).get(query);
};

export {
  getFractionAmount,
  getPartner,
  getBakeryName,
  getBakeryDomain,
  getPageName,
  invalidPhoneMessage,
  getAmount,
  scrollToField,
  getQueryParamValue,
};
