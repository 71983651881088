import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import { isUserAllowed } from "utils/permissions";
import NavChild from "./NavChild";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import LinkPopover from "./LinkPopover";

const NavItem = (props) => {
  const {
    item,
    session,
    showMenu,
    activeItemId,
    className,
    handleNavClick,
    regularChildrenClass,
    toggledChildrenClass,
    showChildren,
  } = props;
  const history = useHistory();
  const [showPopover, setShowPopover] = useState(false);

  const userAllowed = isUserAllowed(item);

  const isNavLinkActive = (path, navTo) => {
    const pathParamsList = path.split("/").filter((val) => !!val);
    const navToParamsList = navTo.split("/").filter((val) => !!val);
    if (pathParamsList.length && navToParamsList.length) {
      return pathParamsList[0] === navToParamsList[0];
    }
    return false;
  };

  const isParent = isNavLinkActive(history.location.pathname, item.route);
  const isActive = isParent && !item.children;
  let activeSelectedClass = "hoverable";
  let activeParentClass = "text-dark";
  if (isActive) {
    activeSelectedClass = "";
    activeParentClass = "text-white bg-primary fw-bold";
  }

  const handleChildClick = (child) => {
    history.push(child.route);
    setShowPopover(false);
  };

  const linkPopOver = (
    <Popover id="popover-basic">
      <LinkPopover
        showChildren={showChildren}
        item={item}
        session={session}
        activeItemId={activeItemId}
        handleChildClick={handleChildClick}
      />
    </Popover>
  );

  let childrenClass = showMenu ? regularChildrenClass : toggledChildrenClass;
  childrenClass = isParent ? childrenClass : "d-none";

  const handleClick = () => {
    handleNavClick();
    setShowPopover(false);
  };

  const getChildRoute = (navItem) => {
    let route = navItem.route;
    navItem.children.every((childItem) => {
      const isAllowed = isUserAllowed(childItem, session);
      if (isAllowed) {
        route = childItem.route;
      }
      return !isAllowed;
    });
    return route;
  };

  const getNavLinkRoute = (navItem) => {
    return !navItem.children ? navItem.route : getChildRoute(navItem);
  };

  return userAllowed ? (
    <div className={`sidenav-list-item ${className}`}>
      <div
        className={`${activeSelectedClass} bg-white`}
        onMouseOver={() => {
          !isParent && showChildren && item.children && setShowPopover(true);
        }}
        onMouseLeave={() => setShowPopover(false)}
      >
        <OverlayTrigger
          trigger={["hover", "hover"]}
          placement="auto"
          overlay={linkPopOver}
          show={showPopover}
        >
          <NavLink
            strict
            to={getNavLinkRoute(item)}
            onClick={handleClick}
            className="text-decoration-none"
            id={item.id}
            isActive={() => isActive}
            tabIndex={-1}
          >
            <div
              className={`${activeParentClass} mx-0 py-2 d-flex align-items-center justify-content-between overflow-hidden w-100`}
            >
              <div className="d-flex align-items-center">
                <div className="d-flex justify-content-center item-icon">
                  <FontAwesomeIcon icon={item.icon} />
                </div>
                <div className="text-nowrap">{item.displayName}</div>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="route-icon me-3"
              />
            </div>
          </NavLink>
        </OverlayTrigger>
      </div>
      {showChildren && item.children && (
        <div className={childrenClass}>
          {item.children.map((child, index) => (
            <NavChild
              child={child}
              className="py-2 child-item text-nowrap"
              session={session}
              activeItemId={activeItemId}
              handleChildClick={handleChildClick}
              key={`child_${index}`}
            />
          ))}
        </div>
      )}
    </div>
  ) : null;
};

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  session: PropTypes.object,
  showMenu: PropTypes.bool.isRequired,
  activeItemId: PropTypes.string,
  className: PropTypes.string,
  handleNavClick: PropTypes.func,
  regularChildrenClass: PropTypes.string,
  toggledChildrenClass: PropTypes.string,
  showChildren: PropTypes.bool,
};

NavItem.defaultProps = {
  activeItemId: null,
  className: "",
  session: null,
  handleNavClick: () => {}, //NOSONAR
  regularChildrenClass: "d-block",
  toggledChildrenClass: "d-block",
  showChildren: true,
};

export default NavItem;
