const setSessionStorageItem = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

const getSessionStorageItem = (key) => {
  return window.sessionStorage.getItem(key);
};

const sessionKeys = {
  settingRedirect: "setting_redirect",
};

export { setSessionStorageItem, getSessionStorageItem, sessionKeys };
